import * as React from "react";
import { PageProps } from "gatsby";
import * as styles from "./styles.module.scss";
import Layout from "../../components/layout/layout";
import { Breadcrumb } from "../../components/breadcrumb/breadcrumb";
import { Tips } from "../../components/tips/tips";
import {Helmet} from "react-helmet";

const PreventionsPage: React.FC<PageProps> = ({ location }: PageProps) => {
  return (
    <Layout
      stickyHeader={false}
      hideTips={true}
      hideWhereToBuy={true}
      seoConfig={{
        title: "Öksürük",
        description:
          "Öksürük nedir, öksürüğün nedenleri nelerdir? Hayatımızı aniden zorlaştırabilen öksürük hakkında detaylı bilgi almak için sayfamızı ziyaret edin!.",
      }}
    >
      <Helmet>
          <script type="application/ld+json">
              {`
            {
                "@context": "https://schema.org",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {"@type": "ListItem","position": 1,"name": "Ana Sayfa","item": "https://www.bisolnatur.com.tr/"},
                  {"@type": "ListItem","position": 2,"name": "Öksürük","item": "https://www.bisolnatur.com.tr/oksuruk/"}
                ]
            }
          `}
          </script>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Breadcrumb
        pathname={location.pathname}
        noBg={true}
        className={styles.breadcrumb}
      />
      <Tips noMoreButton={true} orderByOrder={true} />
    </Layout>
  );
};
export default PreventionsPage;
