import * as React from "react";
import * as breadCrumbStyles from "./breadcrumb.module.scss";
import { Link } from "gatsby";
import { BreadcrumbProps } from "./types";
import { useContext } from "react";
import { AppContext } from "../../app-provider";
import classNames from "classnames";
import Container from "../container/container";

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  pathname,
  lastItemTitle,
  noBg,
  className,
}: BreadcrumbProps) => {
  const appContext = useContext(AppContext);
  let crumbsLength = pathname.split("/").length;

  const crumbs = pathname
    .split("/")
    .map((crumb) => (crumb === "" ? "home" : crumb));
  if (crumbs[crumbsLength - 1] === "home") {
    crumbs.splice(-1);
    crumbsLength -= 1;
  }
  const newCrumbs = crumbs.map((crumb, index) => ({
    path:
      index === crumbsLength - 1
        ? pathname
        : appContext.routes[crumb]?.path.replace(
            "$r",
            crumbs[crumbsLength - 1]
          ),
    title:
      index === crumbsLength - 1 && lastItemTitle
        ? lastItemTitle
        : appContext.routes[crumb]?.title,
  }));
  let classes = "breadcrumb";
  if (!noBg) {
    classes += " bg-orange lighten2";
  }
  return (
    <Container fullWidth className={classNames(classes, className)}>
      <Container>
        <nav className={breadCrumbStyles.navHolder}>
          {newCrumbs.map((crumb, i) => (
            <Link key={i} to={crumb.path} className={classNames("blue")}>
              {crumb.title + (i < crumbsLength - 1 ? " > " : "")}
            </Link>
          ))}
        </nav>
      </Container>
    </Container>
  );
};
